<template>
  <div class="bottom_wrap">
    <div class="yqlj_box">
      <div class="yqlj">
        <span>友情链接</span>
        <el-select placeholder="请选择" v-model="value0">
          <el-option
            v-for="item in linkList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <a target="_blank" :href="item.url">{{ item.name }}</a>
          </el-option>
        </el-select>
        <el-select v-model="value2" placeholder="请选择">
          <el-option
            v-for="item in linkList2"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <a target="_blank" :href="item.url">{{ item.name }}</a>
          </el-option>
        </el-select>
        <el-select v-model="value3" placeholder="请选择">
          <el-option
            v-for="item in linkList3"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <a target="_blank" :href="item.url">{{ item.name }}</a>
          </el-option>
        </el-select>
        <!-- <el-select v-model="value3" placeholder="请选择">
          <el-option
            v-for="item in options4"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            <a target="_blank" :href="item.link">{{ item.label }}</a>
          </el-option>
        </el-select> -->
      </div>
    </div>
    <el-row class="b_box">
      <el-col :span="9" :xs="24">
        <div>
          <p>版权所有：株洲市总工会</p>
          <p>电 话：0731-28686186</p>
          <p>邮 箱：zhzhgy@sina.com</p>
          <p>地 址：湖南省株洲市天元区天伦西路86号</p>
        </div>
      </el-col>
      <el-col :span="9" :xs="24">
        <div class="xuxian">
          <p>湘公网安备 43021102000235号 湘ICP备13012697号-2</p>
        </div>
      </el-col>
      <el-col class="code_wrap" :span="6" :xs="24">
        <div class="code_box">
          <div>
            <img src="../assets/images/code_01.png" />
            <p>株洲工会微信</p>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getLinks } from '@/plugins/api'
export default {
  name: 'Bottom',
  props: {
    msg: String
  },
  data() {
    return {
      value0: 1,
      value3: 1,
      value2: 1,
      linkList: [],
      linkList2: [],
      linkList3: []
    }
  },
  mounted() {
    getLinks().then((res) => {
      this.linkList = res[0]
      this.linkList2 = res[1]
      this.linkList3 = res[2]

      this.value2 = res[1][0].id
      this.value3 = res[2][0].id
    })
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/css/variables.scss';
.yqlj {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.yqlj_box {
  // display: none;
  width: $content-width;
  padding: 40px 0 0;
  margin: 0 auto;
}
.bottom_wrap {
  background-color: #cacaca;
  min-width: $content-width;
  .b_box {
    width: $content-width;
    padding: 40px 0;
    margin: 0 auto;
    text-align: left;
    .xuxian {
      height: 100%;
      // padding-left: 20px;
      text-align: center;
      border-left: 2px dashed #a3a2a2;
      border-right: 2px dashed #a3a2a2;
    }
    p {
      line-height: 32px;
      font-size: 14px;
      color: #666;
    }
    .code_box {
      display: flex;
      justify-content: flex-end;
      div {
        text-align: center;
        // &.big {
        width: 170px;
        // }
      }
      img {
        width: 100px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .yqlj_box {
    display: none;
  }
  .bottom_wrap {
    min-width: auto;
  }
  .bottom_wrap .b_box {
    padding: 20px 10px;
    width: 100%;
    p {
      line-height: 22px;
      font-size: 12px;
    }
    .xuxian {
      text-align: left;
      border-left: none;
      border-right: none;
    }
    .code_box {
      justify-content: flex-start;
      div {
        width: auto;
      }
    }
  }
}
</style>
