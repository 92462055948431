<template>
  <div>
    <el-carousel trigger="click" :height="bannerHeight" class="banner_wrap">
      <el-carousel-item
        class="banner_nav"
        v-for="(item, index) in bannerList"
        :key="index"
      >
        <img style="width: 100%" :src="item.img" />
        <!-- <p class="img_text">{{ item.title }}</p> -->
      </el-carousel-item>
    </el-carousel>
    <div class="main_container">
      <el-row class="banner_nav" :gutter="20">
        <el-col :span="12" :xs="24">
          <el-carousel trigger="click" :height="bannerHeight2">
            <el-carousel-item v-for="(item, index) in bannerList2" :key="index">
              <img
                @click="handleGo(item.id, item.url)"
                :src="item.img"
                style="height: 100%"
              />
              <p class="img_text">
                {{ item.title }}
              </p>
            </el-carousel-item>
          </el-carousel>
        </el-col>
        <el-col :span="12" :xs="24">
          <div class="list_nav">
            <div class="l_head">
              <h2 class="l_title">时政要闻</h2>
              <span @click="handleMore(1)"
                >更多 <i class="el-icon-arrow-right"></i
              ></span>
            </div>
            <div class="l_box">
              <ul>
                <li
                  v-for="(item, index) in newsList"
                  :key="index"
                  @click="handleGo(item.id, item.url, 1)"
                >
                  <a class="l_text" href="javascript:void(0)">{{
                    item.title
                  }}</a>
                  <span class="time">{{ item.add_time | timeFilture }}</span>
                </li>
              </ul>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="notice_nav">
        <span class="n_title">通知<br />公告</span>
        <div class="notice_list">
          <el-carousel
            :height="noticeHeight"
            direction="vertical"
            :autoplay="true"
          >
            <el-carousel-item v-for="item in noticeLength" :key="item">
              <el-row class="" :gutter="20">
                <el-col :span="12" :xs="24"
                  ><p
                    class="n_text"
                    @click="
                      handleGo(noticeList[item].id, noticeList[item].url, 5)
                    "
                  >
                    {{ noticeList[item].title }}
                  </p></el-col
                >
                <el-col :span="12" :xs="24"
                  ><p
                    class="n_text"
                    @click="
                      handleGo(
                        noticeList[item + 1].id,
                        noticeList[item + 1].url,
                        5
                      )
                    "
                  >
                    {{ noticeList[item + 1].title }}
                  </p></el-col
                >
              </el-row>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="study_nav">
        <div class="s_img"><img :src="fullPic[1].img" alt="" /></div>
        <el-row :gutter="20">
          <el-col :span="12" :xs="24">
            <div class="list_nav">
              <div class="l_head">
                <h2 class="l_title">
                  <span
                    @click="typeTab(1)"
                    :class="typeIndex === 2 ? 'noAct' : ''"
                    >工会新闻</span
                  >
                  <span
                    @click="typeTab(2)"
                    :class="typeIndex === 1 ? 'noAct' : ''"
                    >基层动态</span
                  >
                </h2>
                <span @click="handleMore(2)"
                  >更多 <i class="el-icon-arrow-right"></i
                ></span>
              </div>
              <div class="l_box">
                <ul>
                  <li
                    v-for="(item, index) in newsList2"
                    :key="index"
                    @click="
                      handleGo(item.id, item.url, typeIndex === 1 ? 2 : 3)
                    "
                  >
                    <a class="l_text" href="javascript:void(0)">{{
                      item.title
                    }}</a>
                    <span class="time">{{ item.add_time | timeFilture }}</span>
                  </li>
                </ul>
              </div>
            </div></el-col
          >
          <el-col :span="12" :xs="24">
            <div class="list_nav">
              <div class="l_head">
                <h2 class="l_title">微信文章</h2>
                <span @click="handleMore(4)"
                  >更多 <i class="el-icon-arrow-right"></i
                ></span>
              </div>
              <div class="l_box">
                <ul>
                  <li
                    v-for="(item, index) in newsList3"
                    :key="index"
                    @click="handleGo(item.id, item.url, 4)"
                  >
                    <a class="l_text" href="javascript:void(0)">{{
                      item.title
                    }}</a>
                    <span class="time">{{ item.add_time | timeFilture }}</span>
                  </li>
                </ul>
              </div>
            </div></el-col
          >
        </el-row>
      </div>
      <div class="work_nav">
        <span class="w_title">业务办理</span>
        <div class="work_list">
          <el-row class="w_nav" :gutter="20" type="flex">
            <el-col :span="6" v-for="(job, index) in jobsList" :key="index">
              <a target="_blank" :href="job.url" class="w_box">
                <img :src="job.icon" />
                <p>{{ job.title }}</p>
              </a>
            </el-col>
          </el-row>
          <!-- <div>
            <i class="el-icon-arrow-left"></i>
            <i class="el-icon-arrow-right"></i>
          </div> -->
        </div>
      </div>
      <div class="study_nav tabs_nav">
        <span class="study_more" @click.stop="handleStudy"
          >更多<i class="el-icon-arrow-right"></i
        ></span>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="专题专栏" name="1">
            <el-row :gutter="zhuanRow">
              <el-col
                class="zhuanti"
                :span="8"
                :xs="12"
                v-for="(item, index) in newsList4"
                :key="index"
                style=""
              >
                <img
                  @click="handleGo2(item.id)"
                  class="img_w"
                  :src="item.img"
                />
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="study_nav tabs_nav">
        <!-- <div class="s_img">
          <img src="../assets/images/study_02.jpg" alt="" />
        </div> -->
        <span class="study_more" @click.stop="handleStudy2"
          >更多<i class="el-icon-arrow-right"></i
        ></span>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="劳动风采" name="1">
            <el-row :gutter="20">
              <el-col :span="8" v-for="(item, index) in newsList5" :key="index">
                <img
                  @click="handleGo3(item.id, item.name)"
                  class="s_img"
                  :src="item.img"
                />
              </el-col>
            </el-row>
            <!-- <div class="s_scrolling">
              <div
                class="s_img"
                v-for="item in newsList5"
                @click="handleGo(item.id, item.url)"
              >
                <img :src="item.img" />
              </div>
            </div> -->
          </el-tab-pane>
        </el-tabs>
        <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="株洲工匠" name="1">
            <div class="s_scrolling">
              <div class="s_img" v-for="i in 10">
                <img src="../assets/images/laomo_01.jpg" />
              </div>
            </div>
          </el-tab-pane>
        </el-tabs> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getBanners, getJobs, getNews, getNewsList } from '../plugins/api'

export default {
  name: 'HomeView',
  components: {},
  filters: {
    timeFilture(val) {
      return val.split(' ')[0]
    }
  },
  data() {
    return {
      zhuanRow: 20,
      bannerHeight: '384px',
      bannerHeight2: '400px',
      noticeHeight: '60px',
      activeName: '1',
      bannerList: [],
      bannerList2: [],
      fullPic: [
        [{}],
        [
          {
            img: ''
          }
        ]
      ],
      jobsList: [],
      newsList: [],
      newsList2: [],
      newsList3: [],
      newsList4: [],
      newsList5: [],
      noticeList: [],
      noticeLength: 0,
      typeIndex: 1
    }
  },
  methods: {
    typeTab(val) {
      this.typeIndex = val
      // newsList2
      if (val === 1) {
        this.getNewsData(2)
      } else {
        this.getNewsData(3)
      }
    },
    handleStudy() {
      this.$router.push({
        path: '/craftsman'
      })
    },
    handleStudy2() {
      this.$router.push({
        path: '/work'
      })
    },
    handleGo2(type) {
      this.$router.push({
        path: '/article',
        query: { type_name: '2', type }
      })
    },
    handleGo(id, url, type) {
      if (url) {
        window.open(url, '_blank')
      } else {
        this.$router.push({
          path: '/textView',
          query: { id, category: this.category, type }
        })
      }
    },
    handleGo3(type, name) {
      this.$router.push({
        path: '/article',
        query: { type, type_name: 3, name }
      })
    },
    handleClick() {},
    handleMore(type) {
      console.log(type)
      this.$router.push({ path: '/article', query: { type } })
    },
    getNewsData(cat_id) {
      getNewsList({ cat_id, page: 1, perpage: 7 }).then((res) => {
        if (cat_id === 1) this.newsList = res.list
        if (cat_id === 2) this.newsList2 = res.list
        if (cat_id === 3) this.newsList2 = res.list
        if (cat_id === 4) this.newsList3 = res.list
        if (cat_id === 5) {
          let resList = res.list
          this.noticeLength = Math.round(resList.length / 2)
          this.noticeList = resList
        }
      })
    },
    init() {
      getBanners({}).then((res) => {
        this.bannerList = res[0]
        this.bannerList2 = res[1]
        this.fullPic = res[2]
      })

      getJobs().then((res) => {
        this.$store.dispatch('setJobs', res)
        this.jobsList = res
      })

      this.getNewsData(1)
      this.getNewsData(2)
      this.getNewsData(4)
      this.getNewsData(5)

      getNews({
        type: 2
      }).then((res) => {
        this.newsList4 = res.slice(0, 6)
      })
      getNews({
        type: 3
      }).then((res) => {
        this.newsList5 = res.length > 3 ? res.slice(0, 3) : res
      })
    }
  },
  mounted() {
    this.init()
    let clientWidth = document.documentElement.clientWidth
    if (clientWidth < 768) {
      this.bannerHeight = '130px'
      this.bannerHeight2 = '200px'
      this.noticeHeight = '40px'
      this.zhuanRow = 10
      sessionStorage.setItem('isPc', 0)
    } else {
      sessionStorage.setItem('isPc', 1)
    }
    console.log(clientWidth)
  }
}
</script>
<style lang="scss" scoped>
@import './css/home.scss';
.main_container {
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .main_container {
    margin-top: 6px;
  }
}
</style>
